import { FC } from "react";
import { buildersBlue, curvyArrow, dashBlue, engage, instagram, iPerson, linkedIn, logoPinkBig, plug, xwitter } from "../../common/svg";

const Contact: FC = () => {
  return (
    <div className="contact relative">
      <div className="contact bg-primary-200 py-16 px-24 w-full">
        <div className="contact-nav flex flex-col items-center">
          <h4 className="font-roxbor font-semibold text-[28.5px] text-grey-400">
            Here's What You Can Do Now
          </h4>
          <span
            className="-mt-1"
            dangerouslySetInnerHTML={{ __html: dashBlue }}
          />
        </div>

        <div className="contact-card flex items-center w-full justify-center gap-x-4 pt-12 pb-3">
          <div className="can-card py-10 px-5 bg-white rounded-[5px] w-[20rem] flex flex-col items-start gap-y-6">
            <span
              className="-mt-1"
              dangerouslySetInnerHTML={{ __html: plug }}
            />

            <div className="font-inter text-grey-400">
              <h5 className="font-semibold text-[15px]">Connect with Us on Socials</h5>
              <p className="font-medium text-[11.5px] mt-1">
              Stay connected and get the latest updates by following us on social media.
              </p>
            </div>
            <div className="flex items-end justify-start py-[8px] w-[110px] gap-x-2.5">
            <span
              className="cursor-pointer hover:border hover:border-gray-400 hover:rounded-full"
              dangerouslySetInnerHTML={{ __html: linkedIn }}
            /><span
            className="cursor-pointer hover:border hover:border-gray-400 hover:rounded-full"
            dangerouslySetInnerHTML={{ __html: instagram }}
          /><span
          className="cursor-pointer hover:border hover:border-gray-400 hover:rounded-full"
          dangerouslySetInnerHTML={{ __html: xwitter }}
        />
            </div>
          </div>

          <div className="can-card py-10 px-5 bg-white rounded-[5px] w-[20rem] flex flex-col items-start gap-y-6">
            <span
              className="-mt-1"
              dangerouslySetInnerHTML={{ __html: engage }}
            />

            <div className="font-inter text-grey-400">
              <h5 className="font-semibold text-[15px]">Engage with Us</h5>
              <p className="font-medium text-[11.5px] mt-1">
                Join our active WhatsApp community to connect, share insights,
                and collaborate with members.
              </p>
            </div>
            <button type="button" className="font-medium text-[12.5px] py-[9px] px-4 bg-primary-400 hover:bg-primary-500 text-white rounded-[10px] w-[110px]" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSd8C998ZzpsoGTo5mbCl8gOXsdVLhX035V0_OflwrQmprx2hg/viewform", "_blank")}>Join Us</button>
          </div>

          <div className="can-card py-10 px-5 bg-white rounded-tr-[150px] rounded-tl-[5px] rounded-b-[5px] w-[20rem] flex flex-col items-start gap-y-6">
            <span
              className="-mt-1"
              dangerouslySetInnerHTML={{ __html: iPerson }}
            />

            <div className="font-inter text-grey-400">
              <h5 className="font-semibold text-[15px] ">Become a Community Leader</h5>
              <p className="font-medium text-[11.5px] mt-1">
              Our community leaders are here to enhance your experience. Apply to become a leader 
              </p>
            </div>
            <button type="button" className="font-medium text-[12.5px] py-[9px] px-4 bg-primary-400 hover:bg-primary-500 text-white rounded-[10px] w-[110px]" onClick={() => window.open("https://forms.gle/P7M4UkXoASYVfEGWA", "_blank")}>Get in Touch</button>
          </div>
        </div>
      </div>

      <div id="upcoming-activities" className="ready-for w-full">
         <img src="/assets/ready-bg.svg" alt="Here's what we're getting ready for" />
      </div>

      <div className="join-us w-full pt-8 pb-16 px-24 bg-secondary-500 flex flex-col items-center">
      <span
            className="self-end"
            dangerouslySetInnerHTML={{ __html: buildersBlue }}
          />

          <div className="flex items-center gap-x-4">
          <span
            className=""
            dangerouslySetInnerHTML={{ __html: logoPinkBig }}
          />
          <h2 className="-mt-3.5 font-roxbor font-normal text-[90px] text-secondary-400">Join Us today!</h2>
          </div>

          <div className="flex flex-col items-center -mt-4">
          <span
            className="z-20"
            dangerouslySetInnerHTML={{ __html: curvyArrow }}
          />
          <button className="-mt-2 z-10 text-white font-inter font-normal text-[14px] bg-[#1C89EE] py-3.5 px-6 rounded-[10px] hover:bg-primary-500" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSd8C998ZzpsoGTo5mbCl8gOXsdVLhX035V0_OflwrQmprx2hg/viewform", "_blank")}>
              Become a member for free
          </button>
          </div>
      </div>
    </div>
  );
};

export default Contact;
