import { FC } from "react";


const Home: FC = () => {
  return (
    <div className="home pt-8 mt-4 relative">
      <div className="home-hero flex items-start relative px-24 w-full">
      <div className="home-text absolute z-10 -mt-4 ml-1 top-0">
        <h1 className="text-[58px] font-roxbor font-extrabold leading-tight text-grey-400">Building the<br /> Future, <b className="text-primary-500">together</b><b className="text-[40px] ml-3">✨</b></h1>
        <p className="text-[13.5px] font-inter font-normal text-grey-400 w-[28rem] mt-2.5">We are the #1 tech community in the MENA region, where collaboration, innovation, and growth thrive. </p>
        <button type="button" className="py-2.5 px-6 bg-primary-500 text-white text-[14px] font-inter font-normal rounded-lg mt-4">Join our community</button>
      </div>

      <div className="home-hero relative z-0">
        <img src="/assets/hero-bg.png" alt="hero-bg" />
      </div>

      </div>

      <div className="home-pink bg-secondary-400 w-full py-20 px-24 flex items-center justify-between">
        <div className="w-[48.5%]">
          <p className="font-inter leading-normal font-normal text-[20.8px] text-white ">Founded in 2024. Our goal is to build the most vibrant and connected tech ecosystem in the MENA region.</p>
          <img className="mt-10" src="/assets/hero-rando1.png" alt="people drinking together" />
        </div>

        <div className="w-[48.5%]">
          <img  className="object-contain" src="/assets/hero-rando2.png" alt="people hanging out" />
        </div>

      </div>

      <div className="home-yellow bg-secondary-200 w-full py-20 px-28">
        <h4 className="font-inter font-[800] text-[21px] text-grey-400 leading-[30px] w-[65rem]">When we moved to Qatar, we felt lost and disconnected, struggling to find like-minded tech enthusiasts.</h4>
        <p className="font-inter font-[700] text-[21px] text-grey-400 leading-[30px] w-[68rem] opacity-50 mt-6">Attending the Web Summit Qatar in February 2024 helped us make initial connections, but most quickly faded because there was no way to foster them after.</p>
        <p className="font-inter font-[700] text-[21px] text-grey-400 leading-[30px] w-[68rem] opacity-50 mt-6">As we shared our experiences with friends across the MENA region, we discovered that this sense of isolation was widespread.</p>
        <p className="font-inter font-[700] text-[21px] text-grey-400 leading-[30px] w-[68rem] opacity-50 mt-6">Driven by Qatar's 2030 National Vision to transform the nation into a knowledge-based economy, we founded Builders Tribe. Our aim is to recreate the supportive, vibrant tech community we experienced in West Africa, but on a larger scale, encompassing the entire MENA region.</p>
      </div>
    </div>
  );
};

export default Home;
