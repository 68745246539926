import { FC } from "react";


const Navbar: FC = () => {
  return (
    <nav className="navbar flex items-center justify-between px-24 py-8">
      <div className="navLogo w-[120px]">
        <img src="/logo.svg" alt="Builder tribe logo" />
      </div>

      <div className="navLinks flex items-center justify-between w-[380px]">
          <a href="#home" className="font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">Home</a>
          <a href="#about-us" className="font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">About us</a>
          <a href="#our-team" className="font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">Our Team</a>
          <a href="#contact-us" className="font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">Contact us</a>
      </div>

      <div className="navBtn">
        <button type="button" className="py-2.5 px-6 bg-primary-500 text-white text-[14px] font-inter font-normal rounded-lg ">Join our community</button>
      </div>
    </nav>
  );
};

export default Navbar;
